<template lang="pug">
main
	Banner
	TwoActive
	FeatureComparison
	Ribbons
	.gifts
		.gifts-wrap
			.bg-grid
				.gifts-title
					img(src="@/assets/anniversary_2023/gifts/loudspeaker.svg" alt="loudspeaker")
					h2 好禮領取搶先看
				.tabs
					ul
						li(:class="{active:activeTab === 'activeTab'}")
							button(type="button" @click="activeTab = 'activeTab'")
								| 英文能力
								br.d-sm-none
								| 分析報告
						li(:class="{active:activeTab === 'books'}")
							button(type="button" @click="activeTab = 'books'")
								| 兒童英文
								br.d-sm-none
								| 繪本有聲書
						li(:class="{active:activeTab === 'topics'}")
							button(type="button" @click="activeTab = 'topics'")
								| 劍橋兒童
								br.d-sm-none
								| 英檢題庫
				.gifts-lightbox
					LightBox(:activeTab='activeTab' v-show='[ "topics" ].includes(activeTab)')
					.analyze(v-show="activeTab === 'activeTab'")
						h3.text-white.text-center.font-weight-bold 孩子英文能力分析
						p.text-white.text-center 了解你現在的英文程度
						img(src="@/assets/anniversary_2023/lightbox/analyze.svg")
					.videoWrap(v-show="activeTab === 'books'")
						video(controls autoplay name="media" width="100%")
							source(src="https://wuwow-store.s3.ap-southeast-1.amazonaws.com/pictures/video/2023_jr_king_arthur_legend_the_sword_in_the_stone_voice_book_trailer" type="video/mp4")
			Carousel
			.note
				h2 機票抽獎 活動注意事項
				.note-item
					h3 一、抽獎資格相關問題
					p 1. 本活動時間為 2023 / 12 / 4 (一) 至 2024 / 1 / 7 (日)，於期間內完成 WUWOW Jr 課程試聽之台灣居民，且非 WUWOW Jr 在期之學員始獲得抽獎資格。
					p 2. 參與活動者請正確填寫表單資料，填寫後5日內將有專人聯繫；若資料有以下情形，將取消抽獎資格： (1)資料填寫有誤，電話空號，無法聯繫 (2)非本人填寫
					p 3.每人僅限一次抽獎名額，重複填寫無法增加中獎機率，請勿重複填寫。
					p 4.符合機票抽獎資格者，將會寄送資格認證信；資格不符者，則不另行通知。
					p 5.任何抽獎資格判定問題，請來信 md6@lioshutan.com 並附上您的姓名/手機/e-mail ，將有專人協助查詢。
				.note-item
					h3 二、得獎相關問題
					p 1.獎項為機票折抵現金 NT$20,000 元，共計乙名。
					p 2.得獎者名單將統一於 2024 / 1 / 31 (三) 公布於此活動頁。
					p 3.若經查中獎人未全數符合活動要求，則不具得獎資格，亦不遞補中獎名單。
					p 4.本抽獎活動屬於機會中獎，依財政部國稅局制定之「各類所得扣繳率標準」規定，中獎者若未滿20歲，須獲得法定監護人同意及代為領取。凡中獎價值超過新台幣$1,000元者，獎項將須列入個人年度綜合所得稅申報(稅額以獎品市價計算)。中獎價值超過新台幣$20,000元者，須繳納10%稅金(稅額以獎品市價計算) 。【六書堂數位學習股份有限公司】將於中獎名單公告一個月內開立、寄發扣繳憑單、中獎領據至中獎者信箱。中獎者若逾時或未提供完整領獎資訊者，視同放棄領獎資格，亦不遞補中獎資格。（若因信箱填寫有異，遺失/無法收取中獎領據等造成逾期影響領獎程序，視同放棄領獎、亦不遞補中獎資格。)
				.note-item
					h3 三、其他規則
					p 1.參與活動之消費者，如有違反本活動規則及辦法，或以惡意之電腦程式或其他明顯違反活動公平性之方式，意圖混淆或影響活動結果者，一經主辦單位發現或經第三人檢舉經查證屬實，主辦單位有權利立即取消其中獎資格，除追回獎品外，並對於任何破壞本活動之行為追溯相關法律責任。
					p 2.活動辦法載明在本活動平臺中，本活動如有未盡事宜，悉依民事法律相關規定辦理，主辦單位保有隨時更改活動細節（參加方式及贈品內容、數量等）、保留、暫停及解釋內容之權利，並可取消任何影響他人中獎權益之中獎者的中獎資格，參加者同意完全依照及遵守本活動之更動決定，絕無異議。修改訊息將於本平臺上公布為準，不另行通知。
				.note-item
					h3 四、機票獎項 注意事項
					p 1.本公司提供獎品僅為機票折抵現金 NT$20,000，若因任何異動及消費者行為所遺失獎金領取，一概不補發。
					p 2.購買機票及航空公司異動、班機延誤...等多項任何旅途異常相關事項，請依航空公司和現行法規為主，本公司一概不承擔任何飛行、旅途、行李...等多項風險補償和補發獎金。
					p 3.若中獎人不符合、不同意、或發現惡意舞弊或盜用他人身份資料進行抽獎活動等情事，主辦單位保有取消中獎資格及參與本活動之權利。
	footer
		ul
			li
				a(href="mailto:service@wuwow.tw")  聯絡信箱  service@wuwow.tw
			li
				a(href="tel:0800-885-315") 免費諮詢電話  0800 - 885 - 315
		p 隱私權政策
		p
			| Copyrights © 2022 六書堂數位學習
			br.d-md-none
			| LIOSHUTAN. All Rights Reserved
	button.scrollTop(type="button" @click="scrollTop()")
		img(src="@/assets/anniversary_2023/scrollTo.svg" alt="scrollTo")
		img(src="@/assets/anniversary_2023/scrollTo-hover.svg" alt="scrollTo")
</template>

<script>
import Banner from './components/Banner.vue';
import TwoActive from './components/TwoActive.vue';
import Ribbons from './components/Ribbons.vue';
import FeatureComparison from './components/FeatureComparison.vue';
import LightBox from './components/LightBox.vue';
import Carousel from './components/Carousel.vue';

import sweetalert from 'sweetalert2';
import moment from 'moment';

export default {

  name: 'Anniversary2023',

  components: {
    Banner,
    TwoActive,
    Ribbons,
    FeatureComparison,
    LightBox,
    Carousel,
  },

  data() {
    return {
      deadlines: '2024-02-07 18:00:00',
      deadlinesAfter: false,
      activeTab: 'activeTab',
    };
  },
  created() {
    // 時間到了會下架周年慶，banner 第一筆
    this.deadlinesAfter = moment(new Date()).isAfter(this.deadlines);
  },
  mounted() {
    if (this.deadlinesAfter) {
      sweetalert.fire({
        title: '活動時間已結束，歡迎至官網查看最新活動',
        icon: 'warning',
        showConfirmButton: false,
        allowOutsideClick: false,
        timer: 3000,
      }).then(() => {
        this.$router.replace({ name: 'home' });
      });
    }
  },
  methods: {
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './style.scss';

main {
	position: relative;
}

.gifts {
	padding-top: 40px;
	background-image:
		url('../../../assets/anniversary_2023/gifts/bg-character.svg'),
		url('../../../assets/anniversary_2023/gifts/bg-people.svg'),
		linear-gradient(180deg, #8DDFD1 52.08%, #BCF6DA 100%);
	background-size: 1919px 587px, 200vw 60vw, cover;
	background-repeat: no-repeat;
	background-position: 50% 0, 50% 100%, 100% 100%;
	padding-bottom: 280px;

	@include md {
		background-size: 1919px 587px, 150vw 45vw, cover;
	}
	@include lg {
		padding-bottom: 490px;
	}

	@include xl {
		background-size: contain, contain, cover;
	}
}

.gifts-wrap {
	padding: 0 16px;
}

.gifts-title {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
	@include xxl {
		margin-bottom: 40px;
	}

	h2 {
		font-size: 20px;
		font-weight: 900;
		border-radius: 20px;
		background: #fff;
		line-height: 31px;
		border: 4px solid #2AB291;
		padding: 10px 14px;
		margin-bottom: 0;
	}

	img {
		width: 60px;
	}
}

.bg-grid {
	border-radius: 20px;
	padding: 20px 10px;
	background: url('../../../assets/anniversary_2023/twoActive/bg-grid-card.svg') repeat center / 160px 180px;
	max-width: 920px;
	margin: 0 auto;
	@include xxl {
		max-width: 1200px;
		padding: 82px 10px 93px 10px;
	}
}

.tabs {
	display: flex;
	align-items: center;
	justify-content: center;
	background: #B4F1E2;
	border-radius: 10px;
	padding: 16px 10px;
	max-width: 608px;
	margin: 0 auto 40px auto;

	@include sm {
		padding: 10px;
		border-radius: 100px;
	}
	@include xxl {
		max-width: 780px;
	}

	>ul {
		padding: 0;
		list-style: none;
		width: 100%;
		margin: 0;
		display: flex;
		justify-content: center;

		li:nth-child(2) {
			margin: 0 10px;

			@include sm {
				margin: 0 20px;
			}
		}

		li {
			@include sm {
				width: calc(33.3333% - 13px);
			}
			&.active button{
				background: #2AB291;
				color: #fff;
			}
		}
	}

	button {
		width: 100%;
		appearance: none;
		border: none;
		outline: none;
		background: #fff;
		border-radius: 25px;
		padding: 10px 12px;
		font-size: 14px;
		font-weight: 700;
		transition: all .4s ease;
		color: #35485A;

		@include sm {
			padding: 10px;
		}

		&:hover {
			background: #2AB291;
			color: #fff;
		}
	}
}

.gifts-lightbox {
	background: #44B19F;
	border-radius: 20px;
	// padding: 40px;
	padding: 10px;
	max-width: 608px;
	margin: 0 auto;
	@include xxl {
		max-width: 1000px;
	}
}

.analyze {
	padding: 40px;

	h2 {
		color: #fff;
		font-size: 21px;
		font-weight: 700;
		letter-spacing: 2px;
	}

	p {
		font-size: 14px;
		font-weight: 700;
		letter-spacing: 1.4px;
	}

	img {
		width: 100%;
	}
}

.note {
	background: #FEFDE1;
	border-radius: 20px;
	padding: 20px 16px;
	line-height: 120%;
	max-width: 920px;
	margin: 40px auto 0 auto;
	@include md {
		padding: 40px;
	}
	@include xxl {
    max-width: 1200px;
  }

	h2 {
		font-weight: 900;
		font-size: 20px;
		line-height: 145%;
		margin-bottom: 40px;
	}
}

.note-item {
	&:not(:last-child) {
		margin-bottom: 40px;
	}

	h3 {
		font-weight: 900;
		font-size: 18px;
	}

	p {
		font-size: 16px;
		font-weight: 400;
		margin-bottom: 0;
	}
}

footer {
	background: #333333;
	color: #fff;
	font-size: 14px;
	padding: 28px 0;
	text-align: center;
	ul {
		list-style: none;
		padding: 0;
		@include md {
			max-width: 80%;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			li {
				padding: 0 12px;
			}
		}
	}
	a {
		color: #fff;
	}
}
.scrollTop {
	position: fixed;
	bottom: 5%;
	right: 2%;
	appearance: none;
	border: none;
	outline: none;
	background: transparent;
	z-index: 20;
	&:hover {
		img:nth-child(1) {
			display: none;
		}
		img:nth-child(2) {
			display: block;
		}
	}
	img:nth-child(1) {
		width: 100px;
		@include lg {
			width: 150px;
		}
	}
	img:nth-child(2) {
		display: none;
		width: 100px;
		@include lg {
			width: 150px;
		}
	}
}
</style>
