<template lang="pug">
section.ribbons
  .celebrate-people-block
    img(class="flag-left" src="@/assets/anniversary_2023/ribbons/flag-left.svg")
    img(class="flag-right" src="@/assets/anniversary_2023/ribbons/flag-right.svg")
    SignForm(:styleData="'green'")
      template(v-slot:title)
        h2 週年慶活動結束囉！
</template>

<script>
import SignForm from './SignForm.vue';

export default {
  components: { SignForm },
};

</script>

<style lang="scss" scoped>
@import '../style.scss';

// 採帶區
.ribbons {
  background-image:
    url('../../../../assets/anniversary_2023/ribbons/celebrate-people.svg'),
    url('../../../../assets/anniversary_2023/ribbons/ribbons.svg'),
    linear-gradient(180deg, #FFF9E2 14.58%, #C8F0FF 100%);
  // background:
  // url('../../../../assets/anniversary_2023/ribbons/ribbons.svg'),
  // linear-gradient(180deg, #FFF9E2 14.58%, #C8F0FF 100%);
  background-size: 807px 200px, cover, cover;
  /* 或者使用 'contain' 根據需要 */
  background-position: 50% 100%, center, center;
  /* 確保圖片居中 */
  background-repeat: no-repeat;
  /* 防止圖片重複 */
  // height: 1080px;

  @include md {
    background-size: 1130px 280px, cover, cover;
  }

  @include xxl {
    background-size: 1600px 396px, cover, cover;
    padding-bottom: 315px;
  }

  .celebrate-people-block {
    width: 100%;
    height: 100%;
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    padding: 80px 16px 271px 16px;
    @include lg {
      padding: 184px 16px 265px 16px;
    }

    .flag-right {
      position: absolute;
      width: 48vw;
      max-width: 542px;
      top: 6px;
      right: 1%;
    }

    .flag-left {
      position: absolute;
      width: 48vw;
      max-width: 542px;
      top: 6px;
      left: 1%;
    }
  }
}</style>
