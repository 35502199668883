<template lang="pug">
div
	.gallery(v-if='[ "topics", "books" ].includes(activeTab)')
		.gallery-pic(:class="{disabled:materials[activeTab][picIndex].disabled}")
			img(:src="materials[activeTab][picIndex].src" alt="教材")
			button(class="prev" type="button" @click="pre()")
				font-awesome-icon(icon="arrow-left")
			button(class="next" type="button" @click="next()")
				font-awesome-icon(icon="arrow-right")
	.thumb-row
		button.thumb(
      :class="{active: picIndex === index}"
      type="button" v-for="(item, index) in materials[activeTab]"
      :key="item.id" @click="picIndex = index"
      :disabled="item.disabled"
    )
			img(:src="item.src" alt="教材")
</template>

<script>
export default {
  name: 'LightBox',

  props: {
    activeTab: {
      type: String,
      default: 'books',
    },
  },

  data() {
    return {
      // active: require('@/assets/event/caves-education-authorize/cross-industry/book1.png'),
      picIndex: 0,
      materials: {
        // 兒童英文
        books: [
          {
            index: 0,
            src: require('@/assets/event/caves-education-authorize/cross-industry/book1-active.png'),
            disabled: false,
          },
          {
            index: 1,
            src: require('@/assets/event/caves-education-authorize/cross-industry/book2-active.png'),
            disabled: false,
          },
          {
            index: 2,
            src: require('@/assets/event/caves-education-authorize/cross-industry/book3-active.png'),
            disabled: false,
          },
          {
            index: 3,
            src: require('@/assets/event/caves-education-authorize/cross-industry/book4-active.png'),
            disabled: false,
          },
          {
            index: 4,
            src: require('@/assets/event/caves-education-authorize/cross-industry/book5-active.png'),
            disabled: false,
          },
        ],
        topics: [
          {
            index: 0,
            src: require('@/assets/anniversary_2023/materials/topics1.jpg'),
            disabled: false,
          },
          {
            index: 1,
            src: require('@/assets/anniversary_2023/materials/topics2.jpg'),
            disabled: false,
          },
          {
            index: 2,
            src: require('@/assets/anniversary_2023/materials/topics3.jpg'),
            disabled: false,
          },
          {
            index: 3,
            src: require('@/assets/anniversary_2023/materials/topics4.jpg'),
            disabled: true,
          },
          {
            index: 4,
            src: require('@/assets/anniversary_2023/materials/topics5.jpg'),
            disabled: true,
          },
        ],
      },
    };
  },
  watch: {
    activeTab() {
      this.picIndex = 0;
    },
  },
  methods: {
    pre() {
      if (this.picIndex === 0) {
        return;
      }
      this.picIndex -= 1;
    },
    next() {
      if (this.picIndex === this.materials[this.activeTab].length - 1) {
        return;
      }
      this.picIndex += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../style.scss';

.gallery {
	text-align: center;
}
.gallery-pic {
	position: relative;
	display: inline-block;
	width: 240px;
	height: 240px;
  background: #000;
	@include md {
		width: 400px;
		height: 400px;
	}
  &.disabled {
    position: relative;
    &::before {
			content: '';
			position: absolute;
			inset: 0;
			background: rgba(0, 0, 0, 0.4);
			backdrop-filter: blur(5px);
		}
		&::after {
			content: '';
			position: absolute;
			inset: 0;
			background: url('../../../../assets/anniversary_2023/lightbox/lock.svg') no-repeat center / 50px 80px;
		}
  }
	button {
    width: 30px;
    height: 30px;
		appearance: none;
		border: none;
		outline: none;
		border-radius: 50%;
		background: transparent;
		border:  2px solid #000;
    @include md {
      height: 40px;
      width: 40px;
    }
	}
	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}
.prev {
	position: absolute;
	left: 0;
	top: 50%;
  transform: translate(-110%, -50%);
  @include md {
    transform: translate(-140%, -50%);
  }
}
.next {
	position: absolute;
	right: 0;
	top: 50%;
  transform: translate(110%, -50%);
  @include md {
    transform: translate(140%, -50%);
  }
}

.gallery {
	position: relative;
	margin: 0 auto 24px auto;
  @include md {
    max-width: 65%;
  }
}
.thumb {
	appearance: none;
	border: none;
	outline: none;
	cursor: pointer;
	margin: 0 5px;
	flex-shrink: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
	height: 100px;
	background: #000;
	position: relative;
  @include md {
    height: 200px;
    width: 200px;
  }
	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
	&.active{
		border: 1px solid #75CDEC;
	}
	&:disabled {
		&::before {
			content: '';
			position: absolute;
			inset: 0;
			background: rgba(0, 0, 0, 0.4);
			backdrop-filter: blur(5px);
		}
		&::after {
			content: '';
			position: absolute;
			inset: 0;
			background: url('../../../../assets/anniversary_2023/lightbox/lock.svg') no-repeat center / 50px 80px;
		}
	}
}
.thumb-row {
	display: flex;
	overflow-x: auto;
	overflow-y: hidden;
	padding-bottom: 10px;
	&::-webkit-scrollbar-thumb {
    background: #B4F1E2;
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    height: 8px;
	}
}
</style>
