<template lang="pug">
section.banner
  .banner-wrap
    .banner-title
      img.d-inline-block(src="@/assets/anniversary_2023/banner/title.svg")
      .banner-rewards
        img(src="@/assets/anniversary_2023/banner/ticket.svg")
        img(src="@/assets/anniversary_2023/banner/buyOnebuy.svg")
    .award-list
      img(class="flat-left" src="@/assets/anniversary_2023/banner/left.svg")
      img(class="flat-right" src="@/assets/anniversary_2023/banner/right.svg")
      h2 機票中獎幸運星出爐啦！
      p.text-center 請中獎幸運星記得前往信箱查收中獎通知信喔！
      .winning-name
        | 黃 x 恩
        br
        span.mb-0(style="font-size: 21px;") 0972xxx655
    //- SignForm
    //-   template(v-slot:title)
    //-     h2 機票中獎幸運星出爐啦！
    //-     .winning-name
    //-       | 黃 x 恩
    //-       br
    //-       span.mb-0(style="font-size: 21px;") 0972xxx655
    //-     p.text-center 請中獎幸運星記得前往信箱查收中獎通知信喔！
    //- form.signUp
    //-   h2 報名領取
    //-   p.text-center
    //-     span.drawingPoint 英文能力分析報告
    //-     | ＆英檢題庫
    //-     br
    //-     | 再抽不
    //-     span.drawingPoint 限航點來回機票
    //-   .signUp-form
    //-     .signUp-input
    //-       .icon
    //-         img(src="@/assets/anniversary_2023/icon/person.svg" alt="person")
    //-       input(type="text" placeholder="家長姓名 (必填)")
    //-     .signUp-input
    //-       .icon
    //-         img(src="@/assets/anniversary_2023/icon/phone.svg" alt="phone")
    //-       input(type="text" placeholder="家長手機 (必填)")
    //-     .signUp-input
    //-       .icon
    //-         img(src="@/assets/anniversary_2023/icon/mail.svg" alt="mail")
    //-       input(type="text" placeholder="家長信箱 (必填)")
    //-     .materials
    //-       a(href="#" class="w-50")
    //-         img(class="w-100" src="@/assets/anniversary_2023/form/career.svg" alt="career")
    //-       a(href="#" class="w-50")
    //-         img(class="w-100" src="@/assets/anniversary_2023/form/ticket.svg" alt="ticket")
    //-     .privacy
    //-       input(type="checkbox" class="d-none" id="privacy")
    //-       label(for="privacy")
    //-         | 我同意 WUWOW 線上英文
    //-         a(href="#") 隱私權全政策
    //-   button(class="signUp-btn" type="button") 免費搶先預約
    //-   p.contactNumber
    //-     span 0800 885 315
    //-     | 撥打專線，立即有專人為您服務
</template>

<script>
import SignForm from './SignForm.vue';
export default {

  name: 'Anniversary2023Banner',

  components: { SignForm },
};
</script>

<style lang="scss" scoped>
@import '../style.scss';

.banner {
  width: 100%;
  height: 1500px;
  background-repeat: no-repeat;
  background-image:
    url('../../../../assets/anniversary_2023/banner/woman-sm.svg'),
    url('../../../../assets/anniversary_2023/banner/dream-sm.svg'),
    url('../../../../assets/anniversary_2023/banner/bg-s.svg');
  background-size: 349px 360px, 1280px 1600px, 768px 1500px;
  background-position: 60% 98%, 60% -280px, 0% 50%;

  @include sm {
    background-size: 349px 360px, 1280px 1600px, 768px 1500px;
    background-position: 50% 100%, 60% -280px, 0% 50%;
  }

  @include md {
    background-image:
      url('../../../../assets/anniversary_2023/banner/woman-sm.svg'),
      url('../../../../assets/anniversary_2023/banner/dream-sm.svg'),
      url('../../../../assets/anniversary_2023/banner/bg-m.svg');
    background-size: 349px 360px, 1280px 1600px, 1280px 1500px;
    background-position: 50% 100%, 98% -280px, 100% 100%;
  }

  @include lg {
    height: auto;
    background-image:
      url('../../../../assets/anniversary_2023/banner/woman.svg'),
      url('../../../../assets/anniversary_2023/banner/dream.svg'),
      url('../../../../assets/anniversary_2023/banner/bg-l.svg');
    background-size: 30vw 31.5vw, 80vw 55.2vw, 1920px 1486px;
    background-position: 10% 28vw, 110% 0%, 0% 50%;
    padding-bottom: 250px;
  }

  @include xxl {
    background-size: 509px 536px, 1604px 1117px, 1920px 1486px;
    background-position: 0% 90%, 110% 0%, 0% 50%;
    padding-bottom: 650px;
  }

  @include xxxl {
    background-size: 509px 536px, 1604px 1117px, cover;
    background-position: 6% 60%, 100% 0%, 100% 100%;
  }
}

.banner-wrap {
  margin: 0 auto;
  padding: 40px 16px 0 16px;

  @include md {
    padding: 40px 54px 0 54px;
  }

  @include lg {
    display: flex;
    align-items: center;
  }

  @include xl {
    max-width: 80vw;
    height: 614px;
  }
}

.banner-title {
  text-align: center;
  position: relative;

  @include lg {
    text-align: left;
  }

  >img {
    width: 70%;

    @include sm {
      width: 55%;
    }
  }
}

.banner-rewards {
  padding: 0 12px;
  margin-bottom: 32px;

  @include md {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    width: 100%;
    max-width: 280px;

    @include sm {
      width: 60%;
      max-width: 400px;
    }
  }

  >img:first-child {
    // margin-right: -10%;
    margin-bottom: -10%;

    @include md {
      margin-bottom: 20px;
      margin-right: -10%;
    }

    @include xl {
      margin-bottom: 40px;
    }
  }

  >img:last-child {

    // margin-left: -10%;
    @include md {
      margin-left: -10%;
    }
  }
}

.signUp {
  border-radius: 20px;
  background: #fff;
  padding: 20px;

  @include md {
    max-width: 520px;
    margin: 0 auto;
    padding: 27px;
  }

  @include lg {
    // max-width: 480px;
    width: 480px;
    flex-grow: 1;
    flex-shrink: 0;
  }

  @include xl {
    flex-shrink: 0;
  }

  >h2 {
    text-align: center;
    font-weight: 900;
    font-size: 18px;
    line-height: 26.1px;
    margin-bottom: 10px;
    @include rwd-font(18px, 28px, 28px);
    @include rwd-lh(145%, 135%, 135%);
  }

  >.winning-name {
    padding: 20px 0 10px 0;
    margin: 20px 0;
    text-align: center;
    background: #FFE776;
    border-radius: 10px;
    font-weight: 900;
    @include rwd-font(32px, 40px, 40px);
    @include rwd-lh(40px, 42px, 42px);
  }

  >p {
    font-weight: 700;
  }
}

.drawingPoint {
  display: inline-block;
  position: relative;
  background: linear-gradient(transparent 60%, #FFCB15 80%, transparent 100%, );
}

.signUp-input {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #FFCB15;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 27px;

  input {
    appearance: none;
    border: none;
    outline: none;
    padding: 8px 6px;
    flex-grow: 1;
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-right: 1px solid #FFCB15;
}

.materials {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  a {
    cursor: auto;
  }
}

.privacy {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #505050;
  line-height: 150%;
  margin-bottom: 24px;
  cursor: pointer;

  a {
    color: #FFCB15;
    font-size: 14px;
  }

  label {
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    user-select: none;
  }

  label::before {
    content: '';
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 2px;
    margin-right: 4px;
    margin-bottom: 0;
    border: 1px solid #505050;
  }

  input:checked~label::before {
    background: #FF9700;
    border: 1px solid #FF9700;
  }

  label::after {
    content: '';
    position: absolute;
    display: inline-block;
    width: 6px;
    height: 10px;
    left: 6px;
    top: 50%;
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    transform: translate(-23%, -68%) rotate(45deg);
  }
}

.signUp-btn {
  appearance: none;
  border: none;
  outline: none;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 25px;
  background: #FFCB15;
  color: #fff;
  padding: 10px 0;

  &:hover {
    background: lighten(#FFCB15, 10%);
  }
}

.signUp>.contactNumber {
  margin-top: 20px;
  text-align: center;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.84px;

  span {
    color: #FFCB15;
    margin-right: 10px;
  }
}
.award-list{
    position: relative;
    border-radius: 20px;
    background: #fff;
    padding: 50px 20px 20px 20px;
    .flat-left, .flat-right{
      position: absolute;
      top: 0;
      width: 45%;
    }
    .flat-left {
      left: 0;
    }
    .flat-right {
      right: 0;
    }

    @include md {
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
        padding: 80px 27px 27px 27px;
    }

    @include lg {
        // max-width: 480px;
        // width: 480px;
        flex-grow: 1;
        flex-shrink: 0;
    }

    @include xl {
        flex-shrink: 0;
    }

    >h2 {
        text-align: center;
        font-weight: 900;
        font-size: 18px;
        line-height: 26.1px;
        margin-bottom: 20px;
        @include rwd-font(18px, 28px, 28px);
        @include rwd-lh(145%, 135%, 135%);
    }

    >p {
      font-weight: 700;
      @include rwd-font(14px, 16px, 16px);
    }
    >.winning-name {
    padding: 20px 0 10px 0;
    margin: 20px 0;
    text-align: center;
    background: #FFE776;
    border-radius: 10px;
    font-weight: 900;
    @include rwd-font(32px, 40px, 40px);
    @include rwd-lh(40px, 42px, 42px);
  }
}
</style>
