<template lang="pug">
section.twoActive
	.twoActive-wrap
		.bg-grid
			h2.twoActive-title
				img(src="@/assets/anniversary_2023/twoActive/title.svg" alt="歡慶週年")
			ul.twoActive-cards
				li.twoActive-card
					.twoActive-card-title 活動一 | 指定方案 買一送一
					.twoActive-card-main
						.phone-gift
							img(src="@/assets/anniversary_2023/twoActive/book.svg" alt="phone 15")
							p.phone-gift-descriptive
								| 年度最殺優惠，
								br.d-lg-none
								| 凡購買指定課程方案享
								span.light-text(style="font-weight:700;") 限量 買一送一
								| ！
								br
								| 兩套全自用，或將一套分享親友共學，都ok!
						.shopper-day
							img(src="@/assets/anniversary_2023/twoActive/shoppingImg.svg" alt="購物節")
							p.shopper-day-descriptive
								span.light-text 12/26 17:00
								| 前購買，
								br
								| 還能參加
								span.light-text 台中購物節活動
								br.d-md-none
								| ，抽百萬現金、汽車、豪宅
						.note
							| 注意事項：詳細優惠方案內容，將由專人聯繫介紹
						button(class="reservation-btn" type="button" @click="scrollTop()") 免費搶先預約
				li.twoActive-card
					.twoActive-card-title 活動二 | 不限航點來回機票免費抽
					.twoActive-card-main
						.twoActive-card-decoration
							img(src="@/assets/anniversary_2023/twoActive/airplane-left.svg" alt="airplane-left")
							img(src="@/assets/anniversary_2023/twoActive/kid-right.svg" alt="kid-right")
						h3.twoActive-card-subTitle.break
							| 免費英文健檢，領取能力
							br.d-md-none
							| 分析報告
							br.d-none.d-md-block
							| 加碼抽機票
						p.twoActive-card-descriptive
							| 孩子習得一口好英文，再出國實戰開口說！
							br
							span.light-text 2024/1/7
							|  前完成免費英文健檢，加碼抽不限航點來回機票。
						h3.twoActive-step-title 如何英文健檢？
						ul.twoActive-step
							li
								h4 step1
								img(src="@/assets/anniversary_2023/twoActive/step1.svg" alt="step1")
								p
									| 1分鐘填單
									br
									| 領好禮
							li
								h4 step2
								img(src="@/assets/anniversary_2023/twoActive/step2.svg" alt="step2")
								p
									| 3⽇內專⼈電話
									br
									| 預約免費英文健檢
							li
								h4 step3
								img(src="@/assets/anniversary_2023/twoActive/step3.svg" alt="step3")
								p
									| 免費體驗
									br
									| 25 分鐘英文健檢
							li
								h4 step4
								img(src="@/assets/anniversary_2023/twoActive/step4.svg" alt="step4")
								p
									| 獲得 6⼤英⽂能⼒
									br
									| 報告書
						h3.twoActive-eligibility-title
							| 怎麼知道自己符合
							br.d-md-none
							| 機票抽獎資格？
						p.twoActive-eligibility-subtitle
							| 免費課程結束後的 3 小時內，將由系統寄發確認信；
							br.d-md-none
							| 資格不符者，則不另行通知。
						.twoActive-eligibility
							div
								img(src="@/assets/anniversary_2023/twoActive/eligibility-mail.svg" alt="eligibility-mail")
								p 打開電子信箱
							div
								img(src="@/assets/anniversary_2023/twoActive/eligibility-chech-mail.png" alt="eligibility-chech-mail")
								p
									| 確認是否收到
									br
									| 獲得抽獎資格信件
						button(class="reservation-btn" type="button" @click="scrollTop()") 預約免費英文健檢 開抽機票
</template>

<script>
export default {
  name: 'TwoActive',

  methods: {
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../style.scss';

.twoActive {
	overflow: hidden;
	padding: 28% 0 160px 0;
	background-image:
		url('../../../../assets/anniversary_2023/twoActive/earth.svg'),
		linear-gradient(180deg, #94E0D3 52.08%, #B1F6D5 100%)
	;
	background-repeat: no-repeat;
	background-size: contain, cover;
	background-position: 0% 100%, center;
	// background: linear-gradient(180deg, #94E0D3 52.08%, #B1F6D5 100%) no-repeat center / cover;
	@include md {
		padding: 165px 0 280px 0;
	}
	@include xl {
		padding: 165px 0 30% 0;
		background-image:
			url('../../../../assets/anniversary_2023/twoActive/earth.svg'),
			url('../../../../assets/anniversary_2023/twoActive/cloud.svg'),
			url('../../../../assets/anniversary_2023/twoActive/cloud.svg'),
			url('../../../../assets/anniversary_2023/twoActive/cloud.svg'),
			url('../../../../assets/anniversary_2023/twoActive/cloud.svg'),
			linear-gradient(180deg, #94E0D3 52.08%, #B1F6D5 100%),
		;
		background-repeat: no-repeat;
		background-position:
			0% 100%,
			99% 10%,
			2% 20%,
			102% 60%,
			0% 80%,
			center
		;
		background-size:
			contain, // 地球
			283px 113px, // 右上
			266px 106px, // 左上
			265px 106px, // 右下
			366px 146px, // 左下
			cover
		;
	}
}

.twoActive-wrap {
	position: relative;
	padding: 0 16px;
}
.bg-grid {
	height: 100%;
	background-image:
		url('../../../../assets/anniversary_2023/twoActive/balloon-right-sm.svg'),
		url('../../../../assets/anniversary_2023/twoActive/balloon-left-sm.svg'),
		url('../../../../assets/anniversary_2023/twoActive/bg-grid-card.svg')
	;
	background-position: 100% 2%, 0% 2%, center;
	background-size: 123px 140px, 123px 140px, 160px 180px;
	background-repeat: no-repeat, no-repeat, repeat;
	border-radius: 4px;
	padding: 0 16px 20px 16px;
	max-width: 840px;
	margin: 0 auto;
	@include xxl {
		position: relative;
		max-width: 1200px;
		background-size: 171px 209px, 171px 209px, 160px 180px;
		background-position: 90% 2%, 10% 4%, center;
		&::after,&::before {
			content: '';
			position: absolute;
			top: 0;
		}
		&::before {
			background: url('../../../../assets/anniversary_2023/twoActive/firework-left.svg') no-repeat center / contain;
			left: 0;
			transform: translateX(-100%);
			width: 327px;
			height: 957px;
		}
		&::after {
			right: 0;
			transform: translateX(100%);
			background: url('../../../../assets/anniversary_2023/twoActive/firework-right.svg') no-repeat center / contain;
			width: 330px;
			height: 960px;
		}
	}
}
.twoActive-title {
	position: absolute;
	width: 100%;
	top: 0%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	background: url('../../../../assets/anniversary_2023/twoActive/ribbon-sm.svg') no-repeat center / 100vw 50vw;
	@include md {
		width: 65%;
		max-width: 600px;
		height: 320px;
		background: url('../../../../assets/anniversary_2023/twoActive/ribbon-sm.svg') no-repeat center / contain;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	img {
		width: 70%;
		max-width: 308px;
	}
}
.twoActive-cards {
	padding: 0;
	margin: 0;
	padding-top: 52%;
	list-style: none;
	@include md {
		padding-top: 152px;
	}
	@include xxl {
		padding-top: 240px;
	}
}
.twoActive-card {
	max-width: 450px;
	margin: 0 auto;
	border-radius: 20px;
	overflow: hidden;
	border: 6px solid #2AB291;
	@include md {
		max-width: 665px;
	}
	@include xxl {
		max-width: 1000px;
	}
	&-title {
		text-align: center;
		background: #2AB291;
		color: #fff;
		font-weight: 900;
		line-height: 135%;
		padding: 18px 8px;
		@include rwd-font(18px, 28px, 28px);
	}
	&-subTitle {
		text-align: center;
		text-align: center;
		font-weight: 900;
		background: url('../../../../assets/anniversary_2023/twoActive/card-title-point-sm.svg') no-repeat 90% 100% / 103px 19px;
		display: inline-block;
		padding: 0px 12px 12px 12px;
		@include rwd-font(18px, 28px, 28px);
		@include md {
			margin-bottom: 28px;
		}
	}
	&-main {
		background: #fff;
		padding: 80px 10px 40px 10px;
		position: relative;
		text-align: center;
		@include md {
			padding: 100px 30px 40px 30px;
		}
		@include xxl {
			padding: 160px 30px 40px 30px;
		}
		&::after,&::before {
			content: '';
			display: inline-block;
			position: absolute;
			width: 50%;
			top: 0;
			aspect-ratio: 160 / 60;
			@include md {
				width: 40%;
			}
		}
		&::before {
			background: url('../../../../assets/anniversary_2023/twoActive/card-ribbon-left-sm.svg') no-repeat center / contain;
			left: -6px;
		}
		&::after {
			background: url('../../../../assets/anniversary_2023/twoActive/card-ribbon-right-sm.svg') no-repeat center / contain;
			right: -6px;
		}
	}
	&:nth-child(2) {
		margin-top: 20px;
		.twoActive-card-main {
			padding: 70px 10px 40px 10px;
			@include md {
				padding: 105px 10px 40px 10px;
			}
		}
		.twoActive-card-decoration {
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: relative;
			margin-bottom: 20px;
			img {
				@include md {
					position: absolute;
					top: 0;
					z-index: 1;
				}
				@include xl {
					top: 20px;
				}
				&:nth-child(1) {
					width: 176px;
					left: 0;
					width: 136px;
					@include xxl {
						left: 10%;
						width: auto;
					}
				}
				&:nth-child(2) {
					width: 89px;
					right: 0;
					@include xxl {
						right: 10%;
					}
				}
			}
		}
		.twoActive-card-subTitle {
			text-align: center;
			text-align: center;
			font-weight: 900;
			background: url('../../../../assets/anniversary_2023/twoActive/card-title-point-sm.svg') no-repeat 90% 100% / 103px 19px;
			line-height: 31px;
			margin-bottom: 30px;
			@include rwd-font(18px, 28px, 28px);
			@include md {
				font-size: 24px;
				margin-bottom: 40px;
			}
			&.break {
				@include md {
					background: url('../../../../assets/anniversary_2023/twoActive/card-title-point-sm.svg') no-repeat 60% 100% / 103px 19px;
				}
			}
		}
		.twoActive-card-descriptive {
			font-size: 16px;
			font-weight: 700;
			text-align: center;
			margin-bottom: 40px;
			@include md {
				margin-bottom: 80px;
			}
		}
		.twoActive-step {
			padding: 0;
			list-style: none;
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;
			width: 100%;
			margin-bottom: 60px;
			@include lg {
				justify-content: center;
			}
			&-title{
				font-size: 18px;
				font-weight: 900;
				margin-bottom: 20px;
			}
			li {
				width: 50%;
				position: relative;
				@include md {
					width: 25%;
				}
				@include lg {
					max-width: 150px;
				}
				h4 {
					font-weight: 700;
					font-size: 18px;
					margin-bottom: 20px;
				}
				img {
					width: 80px;
					margin-bottom: 20px;
				}
				p {
					font-size: 14px;
					font-weight: 400;
				}
				&:nth-child(1), &:nth-child(2) {
					margin-bottom: 40px;
					@include md {
						margin-bottom: 0;
					}
				}
				&:nth-child(1),&:nth-child(3) {
					&::after {
						content: '';
						position: absolute;
						display: inline-block;
						background: url('../../../../assets/anniversary_2023/twoActive/stepArrow.svg') no-repeat center / contain;
						width: 21px;
						height: 24px;
						top: 32%;
						right: 0px;
						transform: translateX(50%);
					}
				}
				&:nth-child(2) {
					@include md {
						&::after {
							content: '';
							position: absolute;
							display: inline-block;
							background: url('../../../../assets/anniversary_2023/twoActive/stepArrow.svg') no-repeat center / contain;
							width: 21px;
							height: 24px;
							top: 32%;
							right: 0px;
							transform: translateX(50%);
						}
					}
				}
			}
		}
	}
}
.twoActive-eligibility {
	padding: 20px 0;
	background: #DAF1FE;
	border-radius: 20px;
	@include md {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	@include lg {
		justify-content: center;
	}
	&-title {
		font-size: 18px;
		font-weight: 900;
		margin-bottom: 20px;
	}
	&-subtitle {
		text-align: center;
		font-size: 14px;
		font-weight: 700;
		margin-bottom: 20px;
	}
	> div {
		@include md {
			max-width: 227px;
			width: 50%;
			padding: 0 12px;
		}
		p {
			text-align: center;
		}
		img {
			display: inline-block;
			width: 180px;
			margin-bottom: 20px;
			@include md {
				width: 100%;
			}
		}
		&:nth-child(1) {
			position: relative;
			margin-bottom: 70px;
			@include md {
				margin-bottom: 0;
			}
			@include lg {
				margin-right: 70px;
			}
			&::after {
				content: '';
				position: absolute;
				background: url('../../../../assets/anniversary_2023/twoActive/eligibility-arrow.svg') no-repeat center / contain;
				width: 51px;
				height: 51px;
				left: 50%;
				top: 100%;
				transform: translate(-50%, 16px);
				@include md {
					left: 100%;
					top: 25%;
					transform: translate(90%, 100%) rotate(-90deg);
				}
				@include lg {
					transform: translate(35%, 100%) rotate(-90deg);
				}
			}
		}
	}
}

.light-text {
	color: #2AB291;
}
.phone-gift {
	text-align: center;
	margin-bottom: 20px;
	@include md {
		border-radius: 20px;
		background: #EFFAFD;
		position: relative;
	}
	> img {
		width: 200px;
		margin-bottom: 20px;
		z-index: 2;
		@include md {
			margin-bottom: 0;
			position: absolute;
			left: -5%;
			width: 140px;
		}
		@include lg {
			width: 120px;
			left: -5%;
		}
		@include xl {
			left: -5%;
		}
		@include xxl {
			left: 9%;
		}
	}
	&-descriptive {
		background: #EFFAFD;
		border-radius: 20px;
		padding: 20px 0;
		font-weight: 900;
	}
}
.shopper-day {
	text-align: center;
	@include md {
		border-radius: 20px;
		background: #EFFAFD;
		position: relative;
	}
	> img {
		width: 215px;
		margin-bottom: 20px;
		@include md {
			margin-bottom: 0;
			position: absolute;
			bottom: 0;
			right: -5%;
			width: 140px;
		}
		@include xxl {
			right: 10%;
		}
	}
	&-descriptive {
		background: #EFFAFD;
		border-radius: 20px;
		padding: 20px 0;
		font-weight: 900;
	}
}
.note {
	background: #FFF2CE;
	padding: 8px 0;
	font-weight: 700;
	border-radius: 10px;
}
.reservation-btn {
	appearance: none;
	border: none;
	outline: none;
	background: #000;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	border-radius: 25px;
	background: #2AB291;
	color: #fff;
	padding: 10px 0;
	max-width: 300px;
	margin: 20px auto 0 auto;
	&:hover {
		background: lighten(#2AB291, 10%);
	}
}
</style>
