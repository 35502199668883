var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "banner"
  }, [_c('div', {
    staticClass: "banner-wrap"
  }, [_c('div', {
    staticClass: "banner-title"
  }, [_c('img', {
    staticClass: "d-inline-block",
    attrs: {
      "src": require("@/assets/anniversary_2023/banner/title.svg")
    }
  }), _c('div', {
    staticClass: "banner-rewards"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/anniversary_2023/banner/ticket.svg")
    }
  }), _c('img', {
    attrs: {
      "src": require("@/assets/anniversary_2023/banner/buyOnebuy.svg")
    }
  })])]), _c('div', {
    staticClass: "award-list"
  }, [_c('img', {
    staticClass: "flat-left",
    attrs: {
      "src": require("@/assets/anniversary_2023/banner/left.svg")
    }
  }), _c('img', {
    staticClass: "flat-right",
    attrs: {
      "src": require("@/assets/anniversary_2023/banner/right.svg")
    }
  }), _c('h2', [_vm._v("機票中獎幸運星出爐啦！")]), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("請中獎幸運星記得前往信箱查收中獎通知信喔！")]), _c('div', {
    staticClass: "winning-name"
  }, [_vm._v("黃 x 恩"), _c('br'), _c('span', {
    staticClass: "mb-0",
    staticStyle: {
      "font-size": "21px"
    }
  }, [_vm._v("0972xxx655")])])])])]);

}]

export { render, staticRenderFns }