var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "feature-comparison"
  }, [_c('div', {
    staticClass: "feature-title"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/anniversary_2023/gifts/loudspeaker.svg"),
      "alt": "loudspeaker"
    }
  }), _c('h2', [_vm._v("給還在尋找栽培寶貝"), _c('br', {
    staticClass: "d-md-none"
  }), _vm._v("們英文方式的父母")])]), _c('div', [_c('div', {
    staticClass: "starting-learning-journey-img"
  }, [_c('img', {
    staticClass: "content-img",
    attrs: {
      "src": require("@/assets/anniversary_2023/feature_comparison/starting-learning-journey.svg"),
      "alt": "這一次換你與你一起走到哪學到哪"
    }
  })]), _c('div', {
    staticClass: "starting-learning-journey-img-rwd"
  }, [_c('img', {
    staticClass: "content-img-rwd",
    attrs: {
      "src": require("@/assets/anniversary_2023/feature_comparison/starting-learning-journey-RWD.svg"),
      "alt": "這一次換你與你一起走到哪學到哪"
    }
  })])]), _c('div', [_c('div', {
    staticClass: "comparisons"
  }, [_c('div', {
    staticClass: "feature-title"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/anniversary_2023/gifts/loudspeaker.svg"),
      "alt": "loudspeaker"
    }
  }), _c('h2', [_vm._v("兒童線上英文 比較")])]), _c('div', {
    staticClass: "table-content-img-scroll-block"
  }, [_c('div', {
    staticClass: "table-content-img-scroll"
  }, [_c('img', {
    staticClass: "table-content-img",
    attrs: {
      "src": require("@/assets/anniversary_2023/feature_comparison/comparisons-table.svg"),
      "alt": "table"
    }
  })])])])]), _c('div', {
    staticClass: "characterization"
  }, [_c('div', {
    staticClass: "feature-title"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/anniversary_2023/gifts/loudspeaker.svg"),
      "alt": "loudspeaker"
    }
  }), _c('h2', [_vm._v("品牌四大特色")])]), _c('div', {
    staticClass: "description"
  }, [_c('div', {
    staticClass: "description-card"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/anniversary_2023/feature_comparison/earth.png"),
      "alt": "earth"
    }
  }), _c('div', {
    staticClass: "description-card-main"
  }, [_c('h4', [_vm._v("黃金雙語、外籍師資")]), _c('p', [_vm._v("提供雙語及外師兩種選擇，全數師資均有 TEFL / TESOL / CELTA 國際教學證照，及多年兒美教學經驗。")])])]), _c('div', {
    staticClass: "description-card"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/anniversary_2023/feature_comparison/book.png"),
      "alt": "earth"
    }
  }), _c('div', {
    staticClass: "description-card-main"
  }, [_c('h4', [_vm._v("符合108課綱、劍橋兒童檢定的敦煌系列教材")]), _c('p', [_vm._v("系統化教材循序漸進學習內容安排，課後提供仿照劍橋兒童英檢題型及練習音檔，紮實學英同時跨領域培養 21st 軟實力。")])])]), _c('div', {
    staticClass: "description-card"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/anniversary_2023/feature_comparison/chart.png"),
      "alt": "earth"
    }
  }), _c('div', {
    staticClass: "description-card-main"
  }, [_c('h4', [_vm._v("對標 CEFR、YLE國際程度分級")]), _c('p', [_vm._v("課程規劃對標國際檢定分為12等級，再搭配教育部108課綱，精準為孩子量身打造程度適中的教材、師資。")])])]), _c('div', {
    staticClass: "description-card"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/anniversary_2023/feature_comparison/personWatch.png"),
      "alt": "earth"
    }
  }), _c('div', {
    staticClass: "description-card-main"
  }, [_c('h4', [_vm._v("25分鐘 1對1真人老師")]), _c('p', [_vm._v("把握注意力黃金時間，1 對 1 家教課隨時掌握孩子學習狀況，確保充分開口練習！")])])])])])]);

}]

export { render, staticRenderFns }